import React, { useState } from 'react'
import Header from '../directives/Header'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../directives/Footer'
import VideoComponent from '../components/VideoComponent'
import { Modal } from 'react-bootstrap';
import InstagramEmbed from '../components/Insta'


const Gallery = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const videos = [
    
    { src:"assets/images/gallery/gallery1.png", title: "image1", type:"even" },
    { src: "assets/videos/video1.mp4", title: "Video 1",type:"odd" },
    { src:"assets/images/gallery/gallery3.png", title: "image2",type:"even" },
    { src: "assets/videos/video2.mp4", title: "Video 2",type:"odd" },
    { src:"assets/images/gallery/gallery5.png", title: "image3",type:"even" },
    { src: "assets/videos/video3.mp4", title: "Video 3",type:"odd" },
    { src:"assets/images/gallery/gallery1.png", title: "image1",type:"even" },
    { src: "assets/videos/video4.mp4", title: "Video 4" ,type:"odd"},
    { src:"assets/images/gallery/gallery1.png", title: "image1",type:"even" },
    { src: "assets/videos/video5.mp4", title: "Video 5" ,type:"odd"},
    { src:"assets/images/gallery/gallery1.png", title: "image1",type:"even" },
    { src: "assets/videos/video6.mp4", title: "Video 6",type:"odd" },
    { src:"assets/images/gallery/gallery1.png", title: "image1",type:"even" },
    { src: "assets/videos/video7.mp4", title: "Video 7" ,type:"odd"},
    { src:"assets/images/gallery/gallery1.png", title: "image1",type:"even" },
    { src: "assets/videos/video8.mp4", title: "Video 8" ,type:"odd"},
    { src:"assets/images/gallery/gallery1.png", title: "image1",type:"even" },
    { src: "assets/videos/video9.mp4", title: "Video 9",type:"odd" },
    
  ];

  const handleVideoClick = (videoUrl) => {
    setSelectedVideo(videoUrl);
    setIsPopupOpen(true);
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setSelectedVideo("");
    setIsPopupOpen(false);
  };
  const closeImagePopup = () => {
    setSelectedImage("");
    setIsPopupOpen(false);
  };

  const handleMouseEnter = (event) => {
    const video = event.target;
    if (video.paused) {
      video.play().catch((error) => console.error("Play interrupted:", error));
    }
  };

  const handleMouseLeave = (event) => {
    const video = event.target;
    if (!video.paused) {
      video.pause();
    }
  };

  return (
    <>
        <Header />

        <Container className="align-items-center  d-flex flex-column">
        <p className="s2heading tetx-center">OUR GALLERY</p>
      
        </Container>

        <Container className='py-5'>
        <div className='d-flex flex-row gap-3 custom-scrollbar' style={{overflowX:"scroll", width:"100%"}}>
          <div >
          <InstagramEmbed url="https://www.instagram.com/p/DDbuARZT5Ds/?igsh=ZGxvZDU4bjg3MzBl" />
          </div>
          <div >
          <InstagramEmbed url="https://www.instagram.com/p/DDZfsozt0nu/?igsh=MW9laTZha3BzajYzcg==" />
          </div>
          <div >
          <InstagramEmbed url="https://www.instagram.com/reel/DDXI4r5zQ5w/?igsh=NnIwMWh1bWN3cnJ1" />
          </div>
          <div >
          <InstagramEmbed url="https://www.instagram.com/p/DDZK2LMpMdx/?igsh=MWpnenBndjZkNHI5dw==" />
          </div>
          <div >
          <InstagramEmbed url="https://www.instagram.com/p/DDWuVdutZB9/?igsh=MXFtZDZqcnFjYmE3dA==
" />
          </div>
        </div>
        </Container>
        <Container className="mb-5">
      
        <div className="s7gallery">
        <div class="s7row d-flex justify-content-evenly gy-3"> 
        {videos.map((video, index) => {
          if(video.type == "odd"){
            return(
              <>
              <div class="s7column mb-4">
   
    {/* <img src="assets/images/gallery/gallery2.png" className="w-100" /> */}

    <div
            className=""
          
            onClick={() => handleVideoClick(video.src)}
            style={{ cursor: "pointer" , objectFit:"cover" }}
          >
            <video
              src={video.src}
              style={{ width: "100%", borderRadius: "5px",maxHeight:"229px", objectFit:"cover" }}
              controls={false}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              muted
            />
           
          </div>
  
  </div>
              </>
            )
          }
          else{
return(
  <>
  <div
  className="s7column gallery-images mb-4"
  onClick={() => handleImageClick(video.src)}
>
  <img
    src={video.src}
    className="w-100 gallery-image"
    style={{ maxHeight: "229px", objectFit: "cover" }}
  />
</div>

  </>
)
          }
        })}

  
 

</div>
        </div>
     
      </Container>

     
      <Footer />
      <Modal show={isPopupOpen} onHide={closePopup} centered>
        <Modal.Body className="position-relative">
          <button
            onClick={closePopup}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer",
              border:"none",
              zIndex:1
            }}
          >
            &times;
          </button>
          {selectedVideo && (
            <video
              src={selectedVideo}
              controls
              autoPlay
              style={{ width: "100%", maxHeight: "70vh" }}
            />
          )}
        </Modal.Body>
      </Modal>
      <Modal show={isPopupOpen} onHide={closePopup} centered>
        <Modal.Body className="position-relative">
          <button
            onClick={closePopup}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              background: "transparent",
              fontSize: "24px",
              cursor: "pointer",
              color:"white",
              border:"none",
              zIndex:1
            }}
          >
            &times;
          </button>
          {selectedImage && (
            <img
              src={selectedImage}
              
              style={{ width: "100%", maxHeight: "70vh" }}
            />
          )}
        </Modal.Body>
      </Modal>

    </>
  )
}

export default Gallery