import React from 'react'
import { Row, Col, Container } from 'react-bootstrap'
import { Link } from 'react-router'
import config from '../config'

const Footer = () => {
  return (
    <>
        <div className='footer'>
<Container>
<Row className='py-5 gy-3 text-white'>
    <Col lg={3} className='d-flex align-items-center justify-content-center'>
        <img src="assets/images/logo.png" style={{width:200, height:200}} alt="" />
    </Col>
    <Col lg={9}>
<Row className='gy-3'>
    <Col className='d-flex flex-column '>
        <p className='mb-2 footer-heading'>Quick Links</p>
        <Link to={`${config.baseUrl}`} className='mb-1 footer-li'>Home</Link>
        <Link to={`${config.baseUrl}aboutus`} className='mb-1 footer-li'>About Us</Link>
        <Link to={`${config.baseUrl}teams`} className='mb-1 footer-li'>Team</Link>
        {/* <Link to={`${config.baseUrl}aboutus`} className='mb-1 footer-li'>Result</Link> */}
        <Link to={`${config.baseUrl}gallery`} className='mb-1 footer-li'>Gallery</Link>
        <Col>
    {/* <p className='mb-2 footer-heading'>Contact Us</p> */}
        {/* <p className='mb-3 footer-li'>Captian Roopsingh Stadium, Indore Madhya Pradesh, India - 452001</p> */}
        <div className='d-flex gap-2 align-items-center'>
            {/* <p className='mb-0 footer-li'>Follow on :</p> */}
            <div className='d-flex gap-1'>
            <a href='https://www.facebook.com/profile.php?id=61569510575104' target='_blank' className='text-decoration-none' ><img src="assets/images/fb.png" style={{width:18, height:18}} alt="" /></a>
            <a href='https://www.youtube.com/@MPTigersOfficial' target='_blank' className='text-decoration-none' ><img src="assets/images/icon02.png" style={{width:18, height:18}} alt="" /></a>
            <a href='https://www.instagram.com/mptigersofficial/' target='_blank' className='text-decoration-none' ><img src="assets/images/icon03.png" style={{width:18, height:18}} alt="" /></a>
            <a href='https://www.linkedin.com/company/mp-tigers' target='_blank' className='text-decoration-none' ><img src="assets/images/icon04.png" style={{width:18, height:18}} alt="" /></a>
            {/* <a href='https://x.com/mptigers_' target='_blank' className='text-decoration-none' > <img src="assets/images/icon05.png" style={{width:18, height:18}} alt="" /></a>
                
                 */}
                
                
               
            </div>
        </div>
    </Col>
        {/* <Link to={`${config.baseUrl}contactus`} className='mb-0 footer-li'>Contact Us</Link> */}
    </Col>
    {/* <Col>
    <p className='mb-2 footer-heading'>Guidelines</p>
        <p className='mb-1 footer-li'>Privacy Policy</p>
        <p className='mb-1 footer-li'>Terms & Condition</p>
       
    </Col> */}
    <Col>
    {/* <p className='mb-2 footer-heading'>Contact Us</p> */}
        {/* <p className='mb-3 footer-li'>Captian Roopsingh Stadium, Indore Madhya Pradesh, India - 452001</p> */}
        <div className='d-flex gap-2 align-items-center'>
            {/* <p className='mb-0 footer-li'>Follow on :</p> */}
            <div className='d-flex gap-1'>
            {/* <a href='https://www.facebook.com/profile.php?id=61569510575104' target='_blank' className='text-decoration-none' ><img src="assets/images/fb.png" style={{width:18, height:18}} alt="" /></a> */}
            {/* <a href='https://www.youtube.com/@MPTigersOfficial' target='_blank' className='text-decoration-none' ><img src="assets/images/icon02.png" style={{width:18, height:18}} alt="" /></a> */}
            {/* <a href='https://www.instagram.com/mptigersofficial/' target='_blank' className='text-decoration-none' ><img src="assets/images/icon03.png" style={{width:18, height:18}} alt="" /></a> */}
            {/* <a href='https://www.linkedin.com/company/mp-tigers' target='_blank' className='text-decoration-none' ><img src="assets/images/icon04.png" style={{width:18, height:18}} alt="" /></a> */}
            {/* <a href='https://x.com/mptigers_' target='_blank' className='text-decoration-none' > <img src="assets/images/icon05.png" style={{width:18, height:18}} alt="" /></a>
                
                 */}
                
                
               
            </div>
        </div>
    </Col>
</Row>
    </Col>
</Row>
</Container>

<Container>
    <hr style={{color:"white"}} />
   <Row className='justify-content-between text-white'>
    <Col >
<p className='footer-small'>Copyright © 2024 MP Tigers All Rights Reserved</p>
   </Col>
    <Col className='d-flex gap-2 justify-content-end'>
{/* <p className='footer-small'>Privacy Policy</p>
<p className='footer-small'>Terms & conditions</p> */}
{/* <p className='footer-small'>Contact Us</p>
<p className='footer-small'>Cookies</p>
<p className='footer-small'>Sitemap</p> */}
   </Col>
   </Row>

</Container>
        </div>
    </>
  )
}

export default Footer