import React from 'react'
import Header from '../directives/Header'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../directives/Footer'

const BCLDetails = () => {
  return (
    <>
        <Header />

        <Container className='bcl-1 py-5 px-0'>
            <Row className='bcls1'>
<Col className='d-flex flex-column justify-content-center'>
<p className='bcl-heading ps-5'>india’s biggest cricketing extravaganza!</p>
<div className='bcl-tag '>
    <p className='bcl-heading2 ps-5 mb-0'>bada game bada fame</p>
</div>
</Col>
<Col>
    <img src="assets/images/bclpage1.png " className='img-fluid' alt="" />
</Col>
            </Row>

            
        </Container>

        <Container className='my-5'>
        <Row className='gy-3 px-3'>
               <Col lg={6} className='d-flex align-items-center justify-content-center'>
                <img src="assets/images/bcllogo.png" className='img-fluid' alt="" />
               </Col>
               <Col lg={6} className='d-flex flex-column  justify-content-center'>
<p className='bcl-heading3 mb-1'>ABOUT BIG CRICKET LEAGUE </p>
<p className='bcl-para3 mb-1'>Over the past couple of decades, the game of Cricket has almost become a religion in India to the extent that it has overshadowed the hero worship and the glamour of Bollywood. It has also created millions of cricket fans right across India who now enjoy playing the game and try to emulate their cricket idols.</p>
<p className='bcl-para3 mb-1'>Most of these cricket enthusiast start their cricketing journey with a dream to play at the highest level, but most do not achieve their ambitions. Big Cricket League is a unique and first of its kind concept to provide an opportunity for these fans and aspiring local Indian Cricketers to enjoy the game of cricket as never before. </p>
<p className='bcl-para3 mb-1'> Big Cricket League is designed to provide a platform for anyone who loves cricket to fulfill their lifelong dream to play with and against International Icon Cricketers from India and around the world in a T20 tournament with a live broadcast.</p>
               </Col>
            </Row>
        </Container>
        <Container className='my-5'>
        <Row className='gy-3 px-3'>
              
               <Col lg={6} className='d-flex flex-column  justify-content-center'>
<p className='bcl-heading3 mb-1'>MARQUEE CRICKETER OF THE TEAM  </p>
<p className='bcl-para3 mb-1'>Yusuf Pathan, a hard-hitting batsman and offspinner suited to the shorter formats, was first picked for the Baroda Under-16 team in the Vijay Merchant Trophy in 1999-2000. His impressive showings meant he quickly climbed the rungs to the Baroda U-19 and the West Zone U-19 sides. He made his debut in the Ranji Trophy against Saurashtra in 2001-02.</p>
<p className='bcl-para3 mb-1'>Yusuf's impressive showing for Rajasthan Royals in the Indian Premier League in 2008 - 435 runs with four fifties at a strike-rate of 179 - earned him an ODI call-up for the tri- series in Bangladesh and the Asia Cup in Pakistan. He recorded the fastest fifty of the IPL's first season - off 21 balls against Deccan Chargers - and his dazzling all-round show in the final was instrumental in Royals' triumph. </p> </Col>
               <Col lg={6} className='d-flex align-items-center justify-content-center'>
                <img src="assets/images/bclimg2.png" className='img-fluid' alt="" />
               </Col>
            </Row>
        </Container>

       <div className='bcl-s3'>
       <Container className="mb-5 py-5">
        <p className="s3heading text-center text-white mb-5 ">POOL OF MARQUEE PLAYERS</p>
        <Row className="gy-4 px-sm-5 px-0">
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">Shikhar Dhawan</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">IRFAN PATHAN</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">t dilshan</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">suresh raina</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player5.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">yusuf pathan</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player6.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">imran tahir</p>
              </div>
            </div>
          </Col>
        </Row>
        {/* <div className="d-flex justify-content-center w-100">
          <Link to={`${config.baseUrl}bclpage`} className="custom-btn2 px-5 py-2 my-4 text-decoration-none ">View BCL Details</Link>
        </div> */}
      </Container>
       </div>

       <Container className="mb-5">
        <p className="s3heading text-center mb-5">teams we compete</p>
        <Row className="gx-1 gy-3 justify-content-center align-items-center">
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team1.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team2.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team3.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team4.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team5.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team6.png" alt="" />
            </div>
          </Col>
        </Row>
      </Container>

<Footer />
    </>
  )
}

export default BCLDetails