import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "./CustomSlider.css";

// import required modules
import { Pagination, Navigation } from "swiper/modules";
import { Col, Row } from "react-bootstrap";
import { SwiperButton2 } from "./SwiperButton2";

const CustomSlider = () => {
  const [swiper1, setSwiper1] = useState(null);
  const [swiper2, setSwiper2] = useState(null);
  const [swiper3, setSwiper3] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const set1 = [
    {
      id:1,
      name:"Yusuf Pathan",
      ability:"Batting Allrounder",
      matches:174,
      runs:3204,
      wicket:42,
      strikerate:"142.97",
      about:"Yusuf Pathan made a name for himself as one of the hardest hitters of the cricket ball in domestic and international cricket. Yusuf is a typical lower middle order batsman, capable of giving the innings a mighty push in the slog overs with an uncanny ability to clear the ropes. Older brother of Irfan Pathan, Yusuf came on to the international scene after Irfan."
    },
    {
      id:2,
      name:"Pawan Negi",
      ability:"Bowling Allrounder",
      matches:50,
      runs:365,
      wicket:34,
      strikerate:"126.3",
      about:"An all-rounder known for his dynamic batting and clever spin bowling, Pawan Negi brings versatility to the MP Tigers. With a keen cricketing mind and experience in various formats, he's ready to make an impact this season."
    },
    {
      id:3,
      name:"Stuart Binny ",
      ability:"Batting Allrounder",
      matches:95,
      runs:35,
      wicket:22,
      strikerate:"128.84",
      about:"A seasoned cricketer, Stuart Binny is a formidable all-rounder with a wealth of international experience. His powerful batting and reliable seam bowling make him a valuable asset for the MP Tigers in crucial matches."
    },
    {
      id:4,
      name:"Naman Ojha",
      ability:"WK-Batsman",
      matches:113	,
      runs:1554,
      wicket:0,
      strikerate:"118.35",
      about:"A skilled wicketkeeper-batsman, Naman Ojha combines agility behind the stumps with explosive batting prowess. His consistency and leadership will play a vital role in guiding the MP Tigers towards victory."
    },
    {
      id:5,
      name:"Peter Trego",
      ability:"Batting Allrounder",
      matches:211,
      runs:4127,
      wicket:78,
      strikerate:"125.78",
      about:"With explosive batting and strategic bowling, Peter Trego is known for his game-changing performances. The experienced cricketer aims to bring his competitive spirit and expertise to the MP Tigers in the Big Cricket League."
    },
    {
      id:6,
      name:"DILSHAN MUNAWEERA",
      ability:"Batsman",
      matches:13,
      runs:215,
      wicket:0,
      strikerate:"131.1",
      about:"A dynamic batsman with an eye for big scores, Dilshan Munaweera adds flair to the MP Tigers lineup. His aggressive style and international experience will be key as he aims to light up the field this season."
    },
  ]

  const set2 = [
    {
      id:1,
      image:"assets/images/yusufpathan.png"
    },
    {
      id:2,
      image:"assets/images/pawannegi.png"
    },
    {
      id:3,
      image:"assets/images/stuartbinny.png"
    },
    {
      id:4,
      image:"assets/images/namanojha.png"
    },
    {
      id:5,
      image:"assets/images/petertrego.png"
    },
    {
      id:6,
      image:"assets/images/dilshan.png"
    },
  ]
  return (
    <>
      <div className="d-flex gap-1  align-items-center flex-wrap">
        <p className="slider-title mb-0">OUR TIGERS</p>
        <div className="d-flex gap-3 align-items-center ">
        <p className="mb-0">Powered By</p>
        <img
          src="assets/images/sm.png"
          style={{ width: "9rem", height: "2rem" }}
          alt=""
        />
        <img
          src="assets/images/logo.png"
          style={{ width: "4rem", height: "4rem" }}
          alt=""
        />
        </div>
      </div>
      <Row className="custdiv mb-5 mt-3 gy-4">
        <Col lg={7} className="custdiv-left px-3 d-flex align-items-center">
          <Swiper onSwiper={setSwiper1} className="custSwiper"    rewind={true}  slidesPerView="auto"
                  allowTouchMove={false}>
          {set1.map((item,index) =>{
            return(
              <>
              <SwiperSlide>
              <div>
                <p className="cust-heading mb-1 text-uppercase">{item.name}</p>
                <div
                  style={{
                    backgroundColor: "#08005424",
                    width: "fit-content",
                    borderRadius: 4,
                  }}
                  className="px-3 py-1 mb-4"
                >
                  {item.ability}
                </div>

                <Row className="cust-detail mb-3">
                  <Col className="cust-detail1">
                    <p className="mb-1 cust-stat1">{item.matches}</p>
                    <p className="mb-0 cust-stat2">Matches</p>
                  </Col>
                  <Col className="cust-detail1">
                    <p className="mb-1 cust-stat1">{item.runs}</p>
                    <p className="mb-0 cust-stat2">Runs</p>
                  </Col>
                  <Col className="cust-detail1">
                    <p className="mb-1 cust-stat1">{item.wicket}</p>
                    <p className="mb-0 cust-stat2">Wickets</p>
                  </Col>
                  <Col>
                    <p className="mb-1 cust-stat1">{item.strikerate}</p>
                    <p className="mb-0 cust-stat2">Strike Rates</p>
                  </Col>
                </Row>
                <p style={{ color: "#747474" }}>
                 {item.about}
                </p>
              </div>
            </SwiperSlide>
              </>
            )
          })}
         
          </Swiper>
        </Col>
        <Col lg={5} className="custdiv-middle position-relative">
          <div
            className="text-end position-absolute w-100"
            style={{ top: "50%", left:0, zIndex:2 }}
          >
            <SwiperButton2
              swiper1={swiper1}
              swiper2={swiper2}
              swiper3={swiper3}
            />
          </div>
          <Swiper onSwiper={setSwiper2} className="custSwiper"  slidesPerView="auto"
                  allowTouchMove={false}    rewind={true}>
          {set2.map(( item,index) =>{
            return(
              <>
              <SwiperSlide key={index}>
              <div className="d-flex flex-column align-items-center position-relative ">
              <div style={{height:400, width:400,
              position:"absolute",
              bottom:0,
              borderTopLeftRadius:"200px",
              borderTopRightRadius:"200px",
               background: "linear-gradient(180deg, #D98900 0%, rgba(217, 137, 0, 0.07) 58.56%, rgba(217, 137, 0, 0) 100%)"
}}></div>
                <img
                  src={item.image}
                  className="img-fluid cust-img mt-5"
                  alt=""
                  style={{zIndex:1}}
                />
              </div>
            </SwiperSlide>
              </>
            )
          })}
       
        
          </Swiper>
        </Col>

        {/* <Col lg={2} className="custdiv-right">
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            navigation={true}
            onSwiper={setSwiper3}
            modules={[Pagination, Navigation]}
            className="custSwiper"
          >
            <SwiperSlide>Slide 1</SwiperSlide>
            <SwiperSlide>Slide 2</SwiperSlide>
            <SwiperSlide>Slide 3</SwiperSlide>
            <SwiperSlide>Slide 4</SwiperSlide>
            <SwiperSlide>Slide 5</SwiperSlide>
            <SwiperSlide>Slide 6</SwiperSlide>
            <SwiperSlide>Slide 7</SwiperSlide>
            <SwiperSlide>Slide 8</SwiperSlide>
            <SwiperSlide>Slide 9</SwiperSlide>
          </Swiper>
        </Col> */}
      </Row>
    </>
  );
};

export default CustomSlider;
