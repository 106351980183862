import React from "react";
import Header from "../directives/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../directives/Footer";

const PressRelease = () => {

  const newsdata=[
    {
      id:1,
      link:"https://cggrameen.com/?p=25545",
      image:"assets/images/press1.jpg",
      title:"मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स",
      date:"December 7, 2024"
    },
    {
      id:2,
      link:"https://youtu.be/SnjVDEAPoV8",
      image:"assets/images/press2.png",
      title:"मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स",
      date:"December 7, 2024"
    },
    {
      id:3,
      link:"https://www.youtube.com/watch?v=AbWhlWZ6bPo",
      image:"assets/images/press3.png",
      title:"MP के Star बल्लेबाज नमन ओझा के साथ क्रिकेट की खास बातें: MP के युवा खिलाड़ियों का सफर!",
      date:"December 7, 2024"
    },
    {
      id:4,
      link:"https://youtu.be/as8zhCDRA4I",
      image:"assets/images/press4.png",
      title:"मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स लांच",
      date:"December 7, 2024"
    },
    {
      id:5,
      link:"https://www.haritanjaliexpress.com/?p=31274",
      image:"assets/images/press5.jpg",
      title:"मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स",
      date:"December 7, 2024"
    },
    {
      id:6,
      link:"https://dabangswar.com/single-page/13293",
      image:"assets/images/press1.jpg",
      title:"मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स",
      date:"December 7, 2024"
    },
    {
      id:7,
      link:"https://youtu.be/WWbbdhTLzZk",
      image:"assets/images/press7.png",
      title:"मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स",
      date:"December 9, 2024"
    },
    {
      id:8,
      link:"https://localindore.in/mp-tigers-will-provide-platform-to-young-talents/",
      image:"assets/images/press5.jpg",
      title:"युवा प्रतिभाओं को मंच देगा एमपी टाइगर्स",
      date:"December 7, 2024"
    },
    
  ]
  return (
    <>
      <Header />

      <Container className="align-items-center  d-flex flex-column">
        <p className="s2heading text-center">Press Release</p>
        <Row  className="d-flex align-items-stretch gy-3">
          <Col lg={4}>
            <a href="https://www.bhaskar.com/local/mp/indore/news/indore-entry-of-mp-tigers-team-in-bcl-134083091.html" target="_blank" className="press">
              <div className="press1">
                <img src="assets/images/news01.png" className="img-fluid" alt="" />
              </div>
              <div className="press2 p-3">
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    borderBottom: "1px solid #00000020",
                    minHeight:"90px"
                  }}
                  className="pb-2"
                >
                 बीसीएल में एमपी टाइगर्स टीम की एंट्री:सूरत में 12 दिसंबर से बिग क्रिकेट लीग; प्रदेश के क्रिकेटर करेंगे परफॉर्म
                </p>
                <div className="d-flex gap-2">
                  <img src="assets/images/calender.png" alt="" />
                  <small style={{ fontWeight: 500 }}>7th Dec 2024</small>
                </div>
              </div>
            </a>
          </Col>
          <Col lg={4}>
            <a href="https://www.etvbharat.com/hi/!state/indore-mp-tigers-team-launch-big-entry-big-cricket-league-2024-madhya-pradesh-news-mps24120903435" className="press">
              <div className="press1">
                <img src="assets/images/local2.png" className="img-fluid" alt="" />
              </div>
              <div className="press2 p-3">
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    borderBottom: "1px solid #00000020",
                    minHeight:"90px"
                  }}
                  className="pb-2"
                >
               बिग क्रिकेट लीग में उतरेगी एमपी टाइगर्स की टीम, 12 दिसंबर से सूरत में मचाएगी धमाल
                </p>
                <div className="d-flex gap-2">
                  <img src="assets/images/calender.png" alt="" />
                  <small style={{ fontWeight: 500 }}>7th Dec 2024</small>
                </div>
              </div>
            </a>
          </Col>
          <Col lg={4}>
            <a href="https://www.haritanjaliexpress.com/?p=31274" className="press">
              <div className="press1">
                <img src="assets/images/local3.png" className="img-fluid" alt="" />
              </div>
              <div className="press2 p-3">
                <p
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    borderBottom: "1px solid #00000020",
                    minHeight:"90px"
                  }}
                  className="pb-2"
                >
                  Madhya Pradesh Big Cricket League Annoucement
                </p>
                <div className="d-flex gap-2">
                  <img src="assets/images/calender.png" alt="" />
                  <small style={{ fontWeight: 500 }}>7th Dec 2024</small>
                </div>
              </div>
            </a>
          </Col>
        </Row>
      </Container>

      <Container className="py-5">
        <p className="s3heading text-black text-center pb-4">
         Latest News
        </p>
        <Row className="d-flex align-items-stretch gy-3">
        {newsdata.map((item,index) =>{
          return(
            <>
            <Col>
            <a href={item.link} className="news text-decoration-none text-black " target="_blank">
              <div className="p-3">
                <img
                  src={item.image}
                  style={{ width: 250, height: 210, borderRadius:5 }}
                  alt=""
                />
              </div>
              <div className="news-bottom">
                <p
                  className="text-start fs-14 pb-2 mb-1"
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    borderBottom: "1px solid #00000020",
                    minHeight:"90px"
                   
                  }}
                >
                  {" "}
                {item.title}
                </p>

                <p className="text-secondary text-start fs-14 my-1">
                 {item.date}
                </p>
              </div>
            </a>
          </Col>
            </>
          )
        })}
       
       
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default PressRelease;
