import React from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';

export const SwiperButton2 = ({ swiper1, swiper2,swiper3 }) => {
    const handlePrev = () => {
    console.log("Prev clicked");
    if (swiper1) {
      console.log("Swiper1 instance:", swiper1);
      swiper1.slidePrev();
    }
    if (swiper2) {
      console.log("Swiper2 instance:", swiper2);
      swiper2.slidePrev();
    }
    if (swiper3) {
      console.log("Swiper3 instance:", swiper3);
      swiper3.slidePrev();
    }
  };

  const handleNext = () => {
    console.log("Next clicked");
    if (swiper1) swiper1.slideNext();
    if (swiper2) swiper2.slideNext();
    if (swiper3) swiper3.slideNext();
  };
  return (
    <div className="swiper-nav-btns2 d-flex gap-2">
      <button
        className="swiper-nav-prev px-2 py-2 rounded-2 primary-color3 d-flex align-items-center justify-content-center"
        onClick={handlePrev}
      >
        <GrPrevious style={{ color: "White" }} />
      </button>
      <button
        className="swiper-nav-next px-2 py-2 rounded-2 primary-color3 d-flex align-items-center justify-content-center"
        onClick={handleNext}
      >
        <GrNext style={{ color: "White" }} />
      </button>
    </div>
  );
};
