import React from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';
import '../App.css'

export const SwiperButton = ({ swiper1, swiper2 }) => {
  const handlePrev = () => {
    if (swiper1) swiper1.slidePrev();
    if (swiper2) swiper2.slidePrev();
  };

  const handleNext = () => {
    if (swiper1) swiper1.slideNext();
    if (swiper2) swiper2.slideNext();
  };

  return (
    <div className="swiper-nav-btns d-flex gap-2 justify-content-end">
      <button
        className="swiper-nav-prev px-2 py-2 rounded-2 primary-color3 d-flex align-items-center justify-content-center"
        onClick={handlePrev}
      >
        <GrPrevious style={{ color: 'White' }} />
      </button>
      <button
        className="swiper-nav-next px-2 py-2 rounded-2 primary-color3 d-flex align-items-center justify-content-center"
        onClick={handleNext}
      >
        <GrNext style={{ color: 'White' }} />
      </button>
    </div>
  );
};
