import React, { useEffect } from 'react';

const InstagramEmbed = ({ url }) => {
  useEffect(() => {
    // Load the Instagram embed script
    const script = document.createElement('script');
    script.async = true;
    script.defer = true;
    script.src = "https://www.instagram.com/embed.js";
    script.onload = () => window.instgrm.Embeds.process(); // Reinitialize embed script
    document.body.appendChild(script);

    // Cleanup the script
    return () => {
      document.body.removeChild(script);
    };
  }, [url]);

  return (
    <div>
      <blockquote
        className="instagram-media"
        data-instgrm-permalink={url}
        data-instgrm-version="14"
        style={{ maxWidth: '100%',  margin: '0 auto' }}
      ></blockquote>
    </div>
  );
};

export default InstagramEmbed;
