import React from 'react'
import Header from '../directives/Header'
import { Col, Container, Row } from 'react-bootstrap'
import Footer from '../directives/Footer'

const Aboutus = () => {
  return (
    <>
        <Header />

      <div className='aboutus1 position-relative'>
      <Container className='text-center aboutus-heading'>
        <p className='s2heading text-center'>About Us</p>
        <p>The MP Tigers, a franchise in the big cricket League, is established in 2024 to represent Madhya Pradesh. This team will feature a star-studded roster, including six renowned former international cricketers, two accomplished former first-class cricketers, and ten promising local aspiring cricketers.</p>
      </Container>
<img src="assets/images/aboutus1.png" className='w-100' alt="" />
      </div>
      <Container>
        <section className=" px-sm-5 px-2 my-5">
          <Row className="gx-4 gy-3">
            <Col
              lg={6}
              md={6}
              sm={12}
              className="d-flex justify-content-center flex-column"
            >
              <p className="s3heading text-black">Mentor of the team</p>
              <p className=" text-black" style={{fontSize:15}}>
                Mr Narendra Hirwani, a former Indian cricketer and renowned
                lig-spinner, is a mentor for the MP Tigers. Known for his
                remarkable test debut where he took 16 wickets in a match
                against the west indies, Hirwani brings extensive cricketing
                expertise and experience to the team. As a mentor, his guidance
                is invaluable in shaping the strategic direction, enhancing the
                team’s bowling strength and fostering the growth of emerging
                talent. His Vast experience at the international and domestic
                levels makes him a key figure in the development of the MP
                Tigers, especially in honing their spin bowling attack and
                overall cricketing skills.
              </p>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="d-flex justify-content-sm-end justify-content-center"
            >
              <div className='' style={{borderRadius:"10"}}>
                <img src="assets/images/mentor.png" className="img-fluid" alt="" />
              </div>
            </Col>
          </Row>
        </section>
      </Container>
<Footer />
    </>
  )
}

export default Aboutus