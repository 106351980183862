import { BrowserRouter, Route, Routes, useLocation } from 'react-router';
import './App.css';
import config from './config.js';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import Home from './pages/Home.js';
import Aboutus from './pages/Aboutus.jsx';
import Teams from './pages/Teams.jsx';
import PressRelease from './pages/PressRelease.jsx';
import Gallery from './pages/Gallery.jsx';
import BCLDetails from './pages/BCLDetails.jsx';
import ContactUs from './pages/ContactUs.jsx';
import { useEffect } from 'react';
function App() {
  const ScrollToTop = () => {
    const { pathname } = useLocation();
  
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  };
  return (
    <div className="App">
    <BrowserRouter>
    <ScrollToTop />
    <Routes>

      <Route path={`${config.baseUrl}`} element={<Home />} />
      <Route path={`${config.baseUrl}aboutus`} element={<Aboutus />} />
      <Route path={`${config.baseUrl}teams`} element={<Teams />} />
      <Route path={`${config.baseUrl}press-release`} element={<PressRelease />} />
      <Route path={`${config.baseUrl}gallery`} element={<Gallery />} />
      <Route path={`${config.baseUrl}bclpage`} element={<BCLDetails />} />
      {/* <Route path={`${config.baseUrl}contactus`} element={<ContactUs />} /> */}
   
      
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
