import React from "react";
import Header from "../directives/Header";
import { Col, Container, Row } from "react-bootstrap";
import Footer from "../directives/Footer";

const Teams = () => {
  return (
    <>
      <Header />

      <Container className="teampage">
        <Row className="py-5">
          <Col
            lg={4}
            className="d-flex align-items-center justify-content-center"
          >
            <img
              src="assets/images/logo.png"
              className="img-fluid"
              style={{ width: 246, height: 246 }}
              alt=""
            />
          </Col>
          <Col lg={8}>
            <div className="text-start text-white">
              <p className="s2heading mb-0">MP Tigers</p>
              <div className="text-white d-flex  align-items-center gap-2 mb-2">
                <p className="mb-0">Powered By :</p>
                <img
                  src="assets/images/sm.png"
                  style={{ width: 160, height: 30 }}
                  alt=""
                />
              </div>
              <p
                style={{ maxWidth: 500, color: "#F1F1F1", lineHeight: "28px" }}
              >
                {" "}
                The MP Tigers, a franchise in the big cricket League, is
                established in 2024 to represent Madhya Pradesh. This team will
                feature a star-studded roster, including six renowned former
                international cricketers, two accomplished former first-class
                cricketers, and ten promising local aspiring cricketers.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container className="text-center my-5">
        <p className="team-title mb-5">OUR TEAM</p>

        <Row className="gy-3">
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player01.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Yusuf Pathan
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player4.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                 Pawan Negi
                </p>

                <p className="mt-1 player-name2">Bowler</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player2.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                Stuart Binny
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player3.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                 Naman Ojha
                </p>

                <p className="mt-1 player-name2">Wicket-keeper batsman</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player19.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                PETER TREGO
                </p>

                <p className="mt-1 player-name2">Opening batter</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player6.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                 DILSHAN MUNAWEERA
                </p>

                <p className="mt-1 player-name2">batter</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player7.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Amit Mishra
                </p>

                <p className="mt-1 player-name2">Bowler</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player8.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                 jatin saxena
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player9.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                MOHAMMED IFRAN KHAN
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player18.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                s vishwas
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player10.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
             mohammed ansari
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player11.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                arnav prasad
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player12.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                 abdul ansari
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player13.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                faizal a
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player14.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
               saumil das
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player15.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                    daivik nayak
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player16.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
               abdul ansari
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
          <Col
            xl={3}
            lg={4}
            md={6}
            className="d-flex align-items-center justify-content-center"
          >
            <div className="player-card">
              <div className="pgback">
                <img src="assets/images/player17.png" className="img-fluid" alt="" />
              </div>
              <div className="pgback2 text-start mt-2 px-3">
                <p
                  className="mb-1 player-name"
                  style={{
                    borderBottom: "1px solid #00000020",
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                 faizal a
                </p>

                <p className="mt-1 player-name2">All Rounder</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default Teams;
