import React from 'react'
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, NavLink } from 'react-router';
import { Offcanvas } from 'bootstrap';
import { Container, Nav, NavDropdown } from 'react-bootstrap';
import { FaHamburger } from 'react-icons/fa';
import { BiLogOutCircle } from 'react-icons/bi';
import '../'
import config from '../config';
const Header = () => {
  const [showNavbar, setShowNavbar] = React.useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  return (
   <>
   <nav className="navbar">
      <Container>
        <Link to={`${config.baseUrl}`} className="logo " >
        <img src="assets/images/logo.png" className='header-logo' alt="" />
        </Link>
        <div className="menu-icon" onClick={handleShowNavbar}>
          <img src="assets/images/menu.png" style={{width:35, height:35}} alt="" />
        </div>
        <div className={`nav-elements    ${showNavbar && "active"}`}>
          <ul className='mb-0'>
            <Link to={`${config.baseUrl}`} className='mb-0 li'>
             Home
            </Link>
            <Link to={`${config.baseUrl}aboutus`} className='li'>
            About Us
            </Link>
            <Link to={`${config.baseUrl}teams`} className='li'>
             Teams
            </Link>
            <Link to={`${config.baseUrl}press-release`} className='li' style={{textWrap:"noWrap"}}>
            Press Release
            </Link>
            <Link to={`${config.baseUrl}gallery`} className='li' style={{fontWeight:500}}>
            Gallery
            </Link>
          </ul>
          {/* <Link to="" className="custom-btn2 ms-sm-0 ms-4  px-5 py-2  text-decoration-none ">Contact Us</Link> */}
        </div>
        
      </Container>
    </nav>
   </>
  )
}

export default Header