import React, { useRef, useState } from "react";
import Header from "../directives/Header";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { SwiperButton } from "../components/SwiperButton";
import { Pagination } from "swiper/modules";
import { GrNext, GrPrevious } from "react-icons/gr";
import CustomSlider from "../components/CustomSlider";
import Footer from "../directives/Footer";
import { Link } from "react-router";
import config from "../config";

const Home = () => {
  const [swiper1, setSwiper1] = useState(null);
  const [swiper2, setSwiper2] = useState(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <>
      <Header />
<div className="s1bg">
<Container className="py-5">
        <section>
          <Row className="section1 gx-4 ">
            <Col
              lg={5}
              className="d-flex align-items-center justify-content-center" style={{zIndex:4}}
            >
              <div className="section1left ">
                <div>
                  <img src="assets/images/section102.svg" className="s1div" alt="" />
                </div>
                <Swiper
                  modules={[Navigation]}
                  className="mySwiper"
                  onSwiper={setSwiper1}
                  slidesPerView="auto"
                  allowTouchMove={false}
                  rewind={true}
                >
                  <SwiperSlide style={{flexDirection:"column"}}>
                    <p className="text-white text-start section1heading">
                    SportsMint proudly announces its ownership of the MP Tigers Team
                    </p>
                    <p className="text-white text-start section1para">
                    SportsMint has officially become the proud owner of the MP Tigers Team. This milestone reflects our commitment to excellence and our passion for cricket.
                    </p>
                  </SwiperSlide>
                  <SwiperSlide style={{flexDirection:"column"}}>
                    {" "}
                    <p className="text-white text-start section1heading">
                    MP Tigers Set for the Big Cricket League Inaugural Season

                    </p>
                    <p className="text-white text-start section1para">
                    The anticipation is building as MP Tigers prepares to make our mark in the inaugural season of the Big Cricket League.

                    </p>
                  </SwiperSlide>
                  <SwiperSlide style={{flexDirection:"column"}}>
                    {" "}
                    <p className="text-white text-start section1heading">
                    Get ready for the debut of a brand-new character from MP!

                    </p>
                    <p className="text-white text-start section1para">
                    A brand-new character from MP is joining the fun. This unique addition will bring fresh energy and excitement to our story.

                    </p>
                  </SwiperSlide>
                  <div className="text-end">
                    <SwiperButton swiper1={swiper1} swiper2={swiper2} />
                  </div>
                </Swiper>
              </div>
            </Col>
            <Col lg={7} className="" style={{zIndex:1}} >
              <Swiper
                modules={[Navigation]}
                className="mySwiper2"
                onSwiper={setSwiper2}
                slidesPerView="auto"
                allowTouchMove={false}
                rewind={true}
              >
                <SwiperSlide>
                  <img src="assets/images/d1.png" className="img-fluid rounded-2" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img src="assets/images/d2.png" className="img-fluid rounded-2" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <img src="assets/images/d3.png" className="img-fluid rounded-2" alt="" />
                </SwiperSlide>
                <div className="text-end"></div>
              </Swiper>
            </Col>
          </Row>
        </section>
      </Container>
</div>
      

      {/* SECTION2 */}

      <section className="mb-5">
        <div className="section2  ">
          <div className="s2 py-5">
            <Row className="container gy-3">
              <Col
                lg={6}
                md={6}
                sm={12}
                className="d-flex flex-column justify-content-center"
              >
                <div>
                  <p className="s2heading">About us</p>
                  <p className="s2para text-white">
                  We are a passionate cricket team driven by talent, enthusiasm, and a love for the game. As part of the Big Cricket League, we aim to create unforgettable experiences both on and off the field.

                  </p>
                  <p className="s2para text-white">
                  Our mission is to showcase exceptional skill and sportsmanship, bringing together fans, players, and the cricket community. With a commitment to excellence, we strive to inspire future generations of cricket enthusiasts.

                  </p>
                </div>
                {/* <Link to={`${config.baseUrl}contactus`} className="custom-btn2 px-4 py-2 text-decoration-none">Contact Us</Link> */}
              </Col>
              <Col
                lg={6}
                sm={12}
                className="d-flex align-items-center justify-content-center s2img"
              >
                <div>
                  <img src="assets/images/img2.png" className="w-100 h-100" alt="" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      {/* SECTION 3 STARTS */}
      <Container>
        <section className="s3 px-sm-5 px-4 py-sm-0 py-4 mb-5">
          <Row className="gx-4 gy-3">
            <Col
              lg={6}
              md={6}
              sm={12}
              className="d-flex justify-content-center flex-column"
            >
              <p className="s3heading text-black">Mentor of the team</p>
              <p className="s2para text-black">
                Mr Narendra Hirwani, a former Indian cricketer and renowned
                lig-spinner, is a mentor for the MP Tigers. Known for his
                remarkable test debut where he took 16 wickets in a match
                against the west indies, Hirwani brings extensive cricketing
                expertise and experience to the team. As a mentor, his guidance
                is invaluable in shaping the strategic direction, enhancing the
                team’s bowling strength and fostering the growth of emerging
                talent. His Vast experience at the international and domestic
                levels makes him a key figure in the development of the MP
                Tigers, especially in honing their spin bowling attack and
                overall cricketing skills.
              </p>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="d-flex justify-content-sm-end justify-content-center"
            >
              <div>
                <img src="assets/images/img3.png" className="img-fluid" alt="" />
              </div>
            </Col>
          </Row>
        </section>
      </Container>

      {/* PLAYER DETAILS STARTS */}
     
    
      {/* SECTION 4 STARTS */}
      <Container className="news-section">
        <div className="d-flex justify-content-between align-items-center flex-wrap gap-3  mb-3">
          <div className="d-flex align-items-center  gap-3 ">
            <p className="s3heading mb-0">Latest News</p>
            <div className="ln-stroke"></div>
          </div>
          <div>
          <Link to={`${config.baseUrl}press-release`} className="custom-btn2 px-5 py-2 text-decoration-none ">View Details</Link>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div>
            <button
             ref={prevRef}
              className="custom-next-button"
              style={{
                backgroundColor: "#fff",
                border: "1px solid white",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                cursor: "pointer",
              }}
            >
              <GrPrevious />
            </button>
          </div>
          <Swiper
          className="d-flex align-items-stretch mySwiper"
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            rewind={true}
            breakpoints={{
              640: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 50,
              },
              1400: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            navigation={{
              prevEl: prevRef.current,
              nextEl: nextRef.current,
            }}
            onBeforeInit={(swiper) => {
              // Assign navigation buttons to Swiper instance
              swiper.params.navigation.prevEl = prevRef.current;
              swiper.params.navigation.nextEl = nextRef.current;
            }}
            modules={[Pagination, Navigation]}
           
          >
            <SwiperSlide style={{ width: "150px", marginRight: 0 }}>
              <a href="https://cggrameen.com/?p=25545" className="news text-decoration-none text-black ">
                <div className="p-3">
                  <img
                    src="assets/images/press1.jpg"
                    style={{ width: 250, height: 210 }}
                    alt=""
                  />
                </div>
                <div className="news-bottom">
                  <p
                    className="text-start fs-14 pb-3 mb-1"
                    style={{ borderBottom: "1px solid #00000020" }}
                  >
                    {" "}
                    मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स
                  </p>

                  <p className="text-secondary text-start fs-14 my-1">
                  December 7, 2024
                  </p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide style={{ width: "150px", marginRight: 0 }}>
              <a href="https://youtu.be/SnjVDEAPoV8" className="news text-decoration-none text-black ">
                <div className="p-3">
                  <img
                    src="assets/images/press2.png"
                    style={{ width: 250, height: 210 }}
                    alt=""
                  />
                </div>
                <div className="news-bottom">
                  <p
                    className="text-start fs-14 pb-3 mb-1"
                    style={{ borderBottom: "1px solid #00000020" }}
                  >
                    {" "}
                    मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स
                  </p>

                  <p className="text-secondary text-start fs-14 my-1">
                  December 7, 2024
                  </p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide style={{ width: "150px", marginRight: 0 }}>
              <a href="https://youtu.be/WWbbdhTLzZk" className="news text-decoration-none text-black ">
                <div className="p-3">
                  <img
                    src="assets/images/press7.png"
                    style={{ width: 250, height: 210 }}
                    alt=""
                  />
                </div>
                <div className="news-bottom">
                  <p
                    className="text-start fs-14 pb-3 mb-1"
                    style={{ borderBottom: "1px solid #00000020" }}
                  >
                    {" "}
                    मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स
                  </p>

                  <p className="text-secondary text-start fs-14 my-1">
                  December 7, 2024
                  </p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide style={{ width: "150px", marginRight: 0 }}>
              <a href="https://youtu.be/as8zhCDRA4I" className="news text-decoration-none text-black ">
                <div className="p-3">
                  <img
                    src="assets/images/press4.png"
                    style={{ width: 250, height: 210 }}
                    alt=""
                  />
                </div>
                <div className="news-bottom">
                  <p
                    className="text-start fs-14 pb-3 mb-1"
                    style={{ borderBottom: "1px solid #00000020" }}
                  >
                    {" "}
                    मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स लांच
                  </p>

                  <p className="text-secondary text-start fs-14 my-1">
                  December 7, 2024
                  </p>
                </div>
              </a>
            </SwiperSlide>
            <SwiperSlide style={{ width: "150px", marginRight: 0 }}>
              <a href="https://www.haritanjaliexpress.com/?p=31274"  className="news text-decoration-none text-black ">
                <div className="p-3">
                  <img
                    src="assets/images/press5.jpg"
                    style={{ width: 250, height: 210 }}
                    alt=""
                  />
                </div>
                <div className="news-bottom">
                  <p
                    className="text-start fs-14 pb-3 mb-1"
                    style={{ borderBottom: "1px solid #00000020" }}
                  >
                    {" "}
                    मध्य प्रदेश की अपनी क्रिकेट टीम स्पोर्ट्समिंट एमपी टाइगर्स
                  </p>

                  <p className="text-secondary text-start fs-14 my-1">
                  December 7, 2024
                  </p>
                </div>
              </a>
            </SwiperSlide>
          
          </Swiper>
          <div>
            <button
             
              ref={nextRef}
              className="custom-prev-button"
              style={{
                backgroundColor: "#fff",
                border: "1px solid white",
                borderRadius: "50%",
                width: "40px",
                height: "40px",
                cursor: "pointer",
              }}
            >
              <GrNext />
            </button>
          </div>
        </div>
      </Container>

      {/* SECTION 5 STARTS */}
      <Container>
        <section className="s3 px-sm-5 px-3  my-5 s5">
          <Row className="gx-4 gy-3 py-4">
            <Col
              lg={6}
              md={6}
              sm={12}
              className="d-flex  justify-content-center align-items-center"
            >
              <div>
                <img src="assets/images/img4.svg" className="img-fluid h-100" alt="" />
              </div>
            </Col>
            <Col
              lg={6}
              md={6}
              sm={12}
              className="d-flex justify-content-center flex-column"
            >
              <p className="s2heading text-white">About team Owner</p>
              <p className="s2para text-white">
                Sportsmint is a skill based fantasy gaming platform works on
                Blockchain technology. It is designed for passionate sports who
                want to take part in fantasy cricket with fair opportunity of
                winning. It offers an engaging experience where users can create
                their fantasy teams, compete in real-time contests, and win
                exciting rewards. The platform combines strategic gameplay with
                a love for sports, providing a seamless and immersive experience
                for fans of cricket, football, and other popular sports. Whether
                you are a casual player or a seasoned strategists, Sportsmint is
                your go-to destination for turning your sports knowledge into
                thrilling victories.
              </p>
            </Col>
          </Row>
        </section>
      </Container>

      <div className="home-player">
      <Container>
        <CustomSlider />
      </Container>
      </div>

      {/* SECTION 6 STARTS */}
      <Container className="mb-5">
        <p className="s3heading text-center mb-4">teams we compete</p>
        <Row className="gx-1 gy-3 justify-content-center align-items-center">
         
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team2.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team3.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team4.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team5.png" alt="" />
            </div>
          </Col>
          <Col
            lg={2}
            md={3}
            sm={4}
            xs={6}
            className="d-flex justify-content-center"
          >
            <div className="s6teams">
              <img src="assets/images/teams/team6.png" alt="" />
            </div>
          </Col>
        </Row>
      </Container>

      {/* SECTION 7 STARTS */}
      <Container className="mb-5">
        <p className="s3heading text-center mb-4">POOL OF MARQUEE PLAYERS</p>
        <Row className="gy-4 px-sm-5 px-0">
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">Shikhar Dhawan</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">IRFAN PATHAN</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">t dilshan</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">suresh raina</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player5.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">yusuf pathan</p>
              </div>
            </div>
          </Col>
          <Col
            className="d-flex justify-content-center"
            lg={4}
            md={4}
            sm={6}
            xs={6}
          >
            <div className="s7card">
              <div className="s7player">
                <img
                  src="assets/images/players/player6.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="s7playername py-2">
                <p className="pname">imran tahir</p>
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-center w-100">
          <Link to={`${config.baseUrl}bclpage`} className="custom-btn2 px-5 py-2 my-4 text-decoration-none ">View BCL Details</Link>
        </div>
      </Container>

    {/* SECTION 9 STARTS */}
    <section className="s9 mb-5 py-5 ">
          <Container className="position-relative">
          <img src="assets/images/logo.png" className="s9logo"  alt="" />
<Row>
  <Col lg={6} md={6} sm={12} className="d-flex align-items-center flex-column justify-content-center">
<p className="jerseyname">
Spot Our Players With MP
Tigers Official Jersey
</p>
<p className="text-white">Show your support and cheer for our players in style. Spot our players rocking the MP Tigers official jersey as they hit the field
</p>
  </Col>
  <Col lg={6} md={6} sm={12}>
    <img src="assets/images/tshirt2.png" className="img-fluid" alt="" />
  </Col>
</Row>
          </Container>
        </section>

      {/* SECTION 8 STARTS */}

      <Container className="mb-5">
      <div className="d-flex justify-content-between align-items-center flex-wrap gap-3  mb-3">
          <div className="d-flex align-items-center  gap-3 ">
            <p className="s3heading mb-0">Gallery</p>
           
          </div>
          <div>
          <Link to={`${config.baseUrl}gallery`} className="custom-btn2 px-5 py-2 text-decoration-none " >View More</Link>
          </div>
        </div>
        <div className="s7gallery">
        <div class="s7row"> 
  <div class="s7column">
    <img src="assets/images/gallery/gallery1.png" className="w-100" />
    <img src="assets/images/gallery/gallery2.png" className="w-100" />
  
  </div>
  <div class="s7column">
   <img src="assets/images/gallery/gallery3.png" className="w-100 h-100"/>
    
  </div>  
  <div class="s7column">
    <img src="assets/images/gallery/gallery4.png" className="w-100" />
    <img src="assets/images/gallery/gallery5.png" className="w-100" />
   
  </div>

</div>
        </div>
      </Container>
<Footer />
    
    </>
  );
};

export default Home;
